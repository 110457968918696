import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import floral from '../assets/floral-department-coloring-page.pdf'
import floralImg from '../assets/floral-small.png'

import dairy from '../assets/dairy-department-coloring-page.pdf'
import dairyImg from '../assets/dairy-small.png'

import cereal from '../assets/cereal-isle-coloring-page.pdf'
import cerealImg from '../assets/cereal-small.png'

import wildflower from '../assets/wildflowers-botanical-coloring-page.pdf'
import wildflowerImg from '../assets/wildflowers-small.png'

import roots from '../assets/roots-botanical-coloring-page.pdf'
import rootsImg from '../assets/roots-small.png'

import potted from '../assets/potted-plant-collection-coloring-page.pdf'
import pottedImg from '../assets/potted-small.png'

import mason from '../assets/mason-jar-coloring-page.pdf'
import masonImg from '../assets/mason-small.png'

const Coloring = () => (
  <Layout>
    <SEO title="Coloring Pages" />
    <h1> Printable Coloring Pages </h1>

     <p> Because things have gotten so hectic, and so many people have been stuck inside, I am putting up free printable hand drawn coloring pages. All of these are free, downloadable, and printable. </p>
     <p> Just click on an coloring page image to download the coloring page! Files are in .pdf format for you to print out and color in. </p>
     <h2> Grocery Store Departments </h2>
    <div className="flex-container">
    <div className="col-4">
        <a className='img-link' href={floral}> 
        <img src = {floralImg} className='coloring-card' alt = "Floral Department Coloring Page" id = "floral" / >
        </a>
     </div>

     <div className="col-4">
        <a className='img-link' href={dairy}> 
        <img src = {dairyImg} className='coloring-card' alt = "Dairy Department Coloring Page" id = "dairy" / >
        </a>
     </div>

     <div className="col-4">
        <a className='img-link' href={cereal}> 
        <img src = {cerealImg} className='coloring-card' alt = "Breakfast Cereal Coloring Page" id = "dairy" / >
        </a>
     </div>
     </div>

     <h2> Botanicals </h2>
    <div className="flex-container">
    <div className="col-4">
        <a className='img-link' href={wildflower}> 
        <img src = {wildflowerImg} className='coloring-card' alt = "Wildflower collection Coloring Page" id = "dairy" / >
        </a>
     </div>

      <div className="col-4">
        <a className='img-link' href={roots}> 
        <img src = {rootsImg} className='coloring-card' alt = "root vegetable collection Coloring Page" id = "dairy" / >
        </a>
     </div>

      <div className="col-4">
        <a className='img-link' href={potted}> 
        <img src = {pottedImg} className='coloring-card' alt = "Potted plant collection coloring page" id = "dairy" / >
        </a>
     </div>

    </div>

    <div className="flex-container">
    <div className="col-4">
        <a className='img-link' href={mason}> 
        <img src = {masonImg} className='coloring-card' alt = "Mason Jar Bouquet Coloring Page" id = "dairy" / >
        </a>
     </div>

      <div className="col-4">
        
     </div>

      <div className="col-4">

     </div>

    </div>


     <p>Please tag me on your colored in pages or shoot me an email with a picture of your work! If you would like to donate to my cause, my venmo username is @Violet-Lingenfelter. </p>
  </Layout>
)

export default Coloring
