import React from "react"

const Footer = () => (
  <div
    style={{
      background: 'honeydew',
      padding: '3rem'
    }}
  >
  <div
    style={{
      textAlign: 'center'
    }}>
  <p>Made with <span style={{color:"lightSalmon"}}>❤</span> in Hollister, CA
  <br />
  Powered by <a href="https://github.com/gatsbyjs/gatsby">Gatsby</a> in Oakland, CA</p>
  </div>
  </div>
)

export default Footer
